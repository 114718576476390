import { useEffect, useState } from "react";

import moment from 'moment';

import { useParams } from "react-router-dom";

import ToastService from "../services/ToastService";

import PollarisApi from "../services/PollarisApi";

import Placeholder from "../components/Placeholder";
import Cobrancas from "../components/cobranca/Cobrancas";

const PageCobranca = () => {

  let loaded = false;

  const toastService = new ToastService();

  const apiPollaris = new PollarisApi();

  const [loading, setLoading] = useState(false);

  const [payment, setPayment] = useState(null);

  const { paymentId } = useParams();

  useEffect(() => {

    if (loaded) return;

    loaded = true;


    loadPayment();

  }, []);

  const loadPayment = () => {

    apiPollaris
      .get(`cobrancas/${paymentId}`)
      .then((response) => {
        const loadedPayment = response;

        console.log(response);;

        document.title = "ERP | Cobrança " + loadedPayment.id;

        setPayment(loadedPayment);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deletar = () => {

    setLoading(true);

    apiPollaris
      .delete(`cobrancas/${paymentId}`)
      .then((response) => {

        setLoading(false);

        if (payment.datapago) {

          toastService.success("Sucesso!", "Cobranca estornada com sucesso!");

        } else {

          toastService.success("Sucesso!", "Cobranca deletada com sucesso!");
        }

        loadPayment();
      })
      .catch((error) => {
        setLoading(false);
        toastService.error("Erro ao deletar cobrança!", error.message);
      });
  }


  return (
    !payment ? <Placeholder /> :
      <div className="container">
        <div className="col-md-8 offset-md-2 mb-3">
          <div className="row mb-3">
            <div className="col-8">
              <span style={{ fontSize: "1.8em" }}>
                Cobrança {" "}
              </span>
              <small className="text-secondary">
                #{payment.id}
              </small>
              <div className="text-secondary" style={{ fontSize: "0.8em" }}>
                {moment(payment.datahora).format("DD/MM/YYYY HH:mm:ss")}
              </div>
            </div>
            <div className="col-4">
              {
                loading ?
                  <button className="float-end btn btn-sm btn-outline-danger mt-3" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    {
                      payment.datapago === null ?
                        <span>
                          {" "} Excluindo ...
                        </span>
                        :
                        <span>
                          {" "} Estornando ...
                        </span>
                    }
                  </button>

                  :
                  payment.dataestorno === null && payment.datadeletado === null ?
                    <button onClick={deletar} className="float-end btn btn-sm btn-outline-danger mt-3">
                      {
                        payment.datapago === null ?
                          <span>
                            <i className="bi bi-trash"></i>
                            {" "} Excluir Cobrança
                          </span>
                          :
                          <span>
                            <i className="bi bi-cash-coin"></i>
                            {" "} Estornar Cobrança
                          </span>
                      }
                    </button>
                    : false

              }
            </div>
          </div>
          <div className="card shadow">
            <div className="p-4">
              <span style={{ fontSize: "0.8em" }} className="text-secondary">
                Valor
              </span>
              <h1>
                R${parseFloat(payment.valor).toFixed(2)}
              </h1>
              <span style={{ fontSize: "0.8em" }} className="text-secondary">
                Forma de Pagamento
              </span>
              <h4>
                {payment.pagamento}
              </h4>
              <span style={{ fontSize: "0.8em" }} className="text-secondary">
                Status
              </span>
              <div>
                {
                  payment.dataestorno === null && payment.datadeletado === null ?
                    payment.datapago ?
                      <span className="badge bg-success">
                        Autorizado em {moment(payment.datapago).format("DD/MM/YYYY HH:mm:ss")}
                      </span>
                      :
                      <span className="badge bg-warning">
                        Aguardando pagamento
                      </span>
                    : false
                }
                {" "}
                {payment.datadeletado ?
                  <span className="badge bg-danger">
                    Cancelado em {moment(payment.datadeletado).format("DD/MM/YYYY HH:mm:ss")}
                  </span>
                  : false
                }
                {" "}
                {payment.dataestorno ?
                  <span className="badge bg-warning text-dark">
                    Estornado em {moment(payment.dataestorno).format("DD/MM/YYYY HH:mm:ss")}
                  </span>
                  : false
                }
              </div>

              {
                payment.dataestorno === null && payment.datadeletado === null ?
                  payment.retorno ?
                    payment.pagamento === "CARTÃO DE CRÉDITO" ?
                      <InformacoesCartaoDeCredito payment={payment} /> :
                      payment.pagamento === "BOLETO" ?
                        <InformacoesBoleto payment={payment} /> :
                        payment.pagamento === "PIX" ?
                          <InformacoesPix payment={payment} /> :
                          null :
                    <div>
                      <span style={{ fontSize: "0.8em" }} className="text-secondary">
                        Informações Adicionais
                      </span>
                      <h4>
                        Lançamento Manual
                      </h4>
                    </div>
                  : false
              }
            </div>
          </div>
        </div>
      </div>
  );
};


const InformacoesCartaoDeCredito = ({ payment }) => {

  const cardData = JSON.parse(payment.retorno);

  return (
    <div>
      <span style={{ fontSize: "0.8em" }} className="text-secondary">
        Número do Cartão
      </span>
      <h4>
        {cardData.Numero}
      </h4>
    </div>
  )
}

const InformacoesBoleto = ({ payment }) => {

  const toastService = new ToastService();

  const boletoData = JSON.parse(payment.retorno);

  const copyCode = () => {

    navigator.clipboard.writeText(boletoData.LinhaDigitavel);

    toastService.info("Código copiado!", "");
  }

  return (
    <div>
      <span style={{ fontSize: "0.8em" }} className="text-secondary">
        Linha Digitavel
      </span>
      <div className="mb-4">
        <h5>
          {boletoData.LinhaDigitavel}
        </h5>
      </div>
      <div className="mb-4">
        <div className="row">
          <div className="col-md-6 mb-3">
            <button onClick={() => copyCode()} className="btn btn-sm btn-outline-secondary col-12 col-md-12"><i className="bi bi-clipboard"></i> Copiar Código</button>
          </div>
          <div className="col-md-6">
            <a href={boletoData.UrlImpressao} target="_blank" className="btn btn-sm btn-outline-dark col-12 col-md-12"><i className="bi bi-printer"></i> Imprimir</a>
          </div>
        </div>
      </div>
    </div>
  )
}


const InformacoesPix = ({ payment }) => {

  const toastService = new ToastService();

  const pixData = JSON.parse(payment.retorno);

  const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${pixData.LinhaCopiaCola}&size=180x180`;

  const copyCode = () => {

    navigator.clipboard.writeText(pixData.LinhaCopiaCola);

    toastService.info("Código copiado!", "");
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <span style={{ fontSize: "0.8em" }} className="text-secondary">
            Qr Code
          </span>
          <div>
            <img className="img-fluid" src={qrCodeUrl} ></img>
          </div>
        </div>
        <div className="col-md-6">
          <span style={{ fontSize: "0.8em" }} className="text-secondary">
            Pix Copia e Cola
          </span>
          <div className="mb-4">
            <span style={{ fontSize: "0.8em" }}>
              {pixData.LinhaCopiaCola}
            </span>
          </div>
          <div className="col-md-6 mb-3">
            <button onClick={() => copyCode()} className="btn btn-sm btn-outline-secondary col-12"><i className="bi bi-clipboard"></i> Copiar</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageCobranca;