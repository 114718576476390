import React from "react";

import Despesas from "../components/despesa/Despesas";

const PageDespesas = (props) => {

  document.title = "Pollaris ERP - Despesas";

  return (<div className="container" >
    <Despesas />
  </div >);
};

export default PageDespesas;