import { useEffect, useState } from "react";

import PollarisApi from "../../services/PollarisApi";

import ToastService from "../../services/ToastService";

import { useForm } from 'react-hook-form';

import SelectColaborador from "../inputs/SelectColaborador";
import moment from "moment";

const FormRepresentante = ({ onSave, selectedRepresentante }) => {

    let loaded = false;

    const [representante, setCustomer] = useState(selectedRepresentante);

    const apiPollaris = new PollarisApi();

    const toastService = new ToastService();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {

        toastService.info("Aguarde..", "Salvando...");

        var currentRepresentante = { ...representante };

        const newRepresentante = { ...currentRepresentante, ...data };

        if (!newRepresentante.nascimento)
            newRepresentante.nascimento = null;

        const onError = (error) => {

            console.error(error);

            const message = error?.message || error.title;

            toastService.error("Erro ao salvar dados", message);

        };

        const onSuccess = (response) => {
            toastService.success("Sucesso..", "Dados salvos com sucesso...");

            onSave();
        };

        if (currentRepresentante.id > 0) {
            apiPollaris
                .put(`representantes/${currentRepresentante?.id}`, newRepresentante)
                .then(onSuccess)
                .catch(onError);
        } else {

            currentRepresentante.ativo = true;

            apiPollaris
                .post(`representantes`, newRepresentante)
                .then(onSuccess)
                .catch(onError);
        }

    }

    return (<form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
            <div className="col-md-6">
                <div className="form-floating mb-3">
                    <input type="text" className="form-control " {...register('nome', { value: representante?.nome, required: true })} />
                    <label>Nome / Razao Social</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-floating mb-3">
                    <input type="text" className="form-control " {...register('fantasia', { value: representante?.fantasia, required: false })} />
                    <label>Apelido / Nome Fantasia</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-floating mb-3">
                    <input type="text" className="form-control" {...register('cnpj', { value: representante?.cnpj, required: true })} />
                    <label>CPF / CNPJ</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-floating mb-3">
                    <input type="text" className="form-control" {...register('ie', { value: representante?.ie, required: false })} />
                    <label>RG / IE</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-floating mb-3">
                    <input type="date" className="form-control" {...register('nascimento', { value: moment(representante?.nascimento).format("YYYY-MM-DD"), required: true })} />
                    <label>Nascimento</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-floating mb-3">
                    <input type="number" step="0.01" className="form-control" {...register('percentual', { value: moment(representante?.percentual).format("YYYY-MM-DD"), required: true })} />
                    <label>Percentual</label>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="form-floating mb-3">
                    <input type="email" className="form-control " {...register('email', { value: representante?.email, required: true })} />
                    <label>Email</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-floating mb-3">
                    <input type="phone" className="form-control " {...register('telefone', { value: representante?.telefone, required: true })} />
                    <label>Telefone</label>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-8">
                <div className="form-floating mb-3">
                    <input type="text" className="form-control " {...register('endereco', { value: representante?.endereco, required: false })} />
                    <label>Endereço</label>
                </div>
            </div>
            <div className="col-md-4">
                <div className="form-floating mb-3">
                    <input type="text" className="form-control " {...register('numero', { value: representante?.numero, required: false })} />
                    <label>Número</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-floating mb-3">
                    <input type="text" className="form-control " {...register('cidade', { value: representante?.cidade, required: false })} />
                    <label>Cidade</label>
                </div>
            </div>
            <div className="col-md-2">
                <div className="form-floating mb-3">
                    <select type="text" className="form-control " {...register('estado', { value: representante?.estado, required: false })} >
                        <option value="">Selecione</option>
                        <option value="AC">AC</option>
                        <option value="AL">AL</option>
                        <option value="AP">AP</option>
                        <option value="AM">AM</option>
                        <option value="BA">BA</option>
                        <option value="CE">CE</option>
                        <option value="DF">DF</option>
                        <option value="ES">ES</option>
                        <option value="GO">GO</option>
                        <option value="MA">MA</option>
                        <option value="MT">MT</option>
                        <option value="MS">MS</option>
                        <option value="MG">MG</option>
                        <option value="PA">PA</option>
                        <option value="PB">PB</option>
                        <option value="PR">PR</option>
                        <option value="PE">PE</option>
                        <option value="PI">PI</option>
                        <option value="RJ">RJ</option>
                        <option value="RN">RN</option>
                        <option value="RS">RS</option>
                        <option value="RO">RO</option>
                        <option value="RR">RR</option>
                        <option value="SC">SC</option>
                        <option value="SP">SP</option>
                        <option value="SE">SE</option>
                        <option value="TO">TO</option>
                    </select>
                    <label>Estado</label>
                </div>
            </div>
            <div className="col-md-4">
                <div className="form-floating mb-3">
                    <input type="text" className="form-control " {...register('bairro', { value: representante?.bairro, required: false })} />
                    <label>Bairro</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-floating mb-3">
                    <input type="text" className="form-control " {...register('cep', { value: representante?.cep, required: false })} />
                    <label>CEP</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-floating mb-3">
                    <input type="text" className="form-control " {...register('senha', { value: representante?.senha, required: true })} />
                    <label>Senha</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className="mb-3">
                    <button type="submit" className="btn form-control col-12 btn-success-pollaris btn-lg" >
                        <i className="bi bi-check"></i> Salvar
                    </button>
                </div>
            </div>
        </div>
    </form>
    );
};

export default FormRepresentante;