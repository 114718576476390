import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import PollarisApi from "../../services/PollarisApi";
import ToastService from "../../services/ToastService";
import { Modal } from "react-bootstrap";

const ContratosCliente = ({ idCliente }) => {

    const pollariApi = new PollarisApi();

    const navigate = useNavigate();

    const toastService = new ToastService();

    const [cliente, setCliente] = useState({});

    const [contratoEdicao, setContratoEdicao] = useState({});

    const [planosEdicao, setPlanosEdicao] = useState([]);

    const [showModalContratoEditar, setShowModalContratoEditar] = useState(false);

    const carregarContratos = () => {

        pollariApi
            .get(`clientes/${idCliente}/dados`)
            .then(response => {
                setCliente(response);
            }).catch(error => {
                console.log(error);
            });
    }

    let loaded = false;

    useEffect(() => {

        if (loaded) return;

        loaded = true;

        carregarContratos();

    }, [idCliente]);

    const liberacaoManual = (contrato, formaPagamento) => {

        const usuario = JSON.parse(localStorage.getItem("authentication"));

        const cobranca = {
            tipo: "MANUAL",
            diasLiberados: contrato.dias,
            idusuario: usuario.customer.id,
            idcontrato: contrato.contrato.id,
            pagamento: formaPagamento,
            valor: contrato.valor,
            datapago: moment().format("YYYY-MM-DD")
        };

        pollariApi
            .post(`cobrancas`, cobranca)
            .then(response => {
                toastService.success("Sucesso", "Contrato liberado com sucesso");
                carregarContratos();
            }).catch(error => {
                console.log(error);
            }
            );
    }

    const gerarCobranca = (contrato, formaPagamento) => {

        toastService.info("Aguarde", "Gerando cobrança...");

        pollariApi
            .post(`cobrancas/${formaPagamento}/${contrato.contrato.id}`, null)
            .then(response => {
                navigate(`/cobranca/${response.id}`);
            }).catch(error => {
                toastService.error("Falha ao gerar cobrança", error.message);
            }
            );
    }


    const alterarAtivo = (contrato, ativo) => {


        const contratoAlterado = {
            ...contrato.contrato,
            ativo: ativo
        };

        pollariApi
            .put(`contratos/${contrato.contrato.id}`, contratoAlterado)
            .then(response => {

                ativo ? toastService.success("Sucesso", "Contrato ativado com sucesso") : toastService.success("Sucesso", "Contrato desativado com sucesso");

                carregarContratos();
            }).catch(error => {
                console.log(error);
            });

    }

    const extenderContrato = (contrato) => {

        pollariApi
            .put(`contratos/${contrato.contrato.id}/estender/5`)
            .then(response => {
                toastService.success("Sucesso", "Contrato estendido com sucesso");
                carregarContratos();
            }).catch(error => {

                toastService.error("Falha ao estender contrato", error.response);
            }
            );

    };

    const editar = (contrato) => {

        pollariApi
            .get(`produtos?programa=${contrato.contrato.programa}`)
            .then(response => {
                setContratoEdicao(contrato);
                setPlanosEdicao(response);
                setShowModalContratoEditar(true);
            }).catch(error => {
                console.log(error);
            });

    };

    
    const salvarContratoEdicao = (e) => {

        e.preventDefault();
        e.stopPropagation();

        pollariApi
            .put(`contratos/${contratoEdicao.contrato.id}`, contratoEdicao.contrato)
            .then(response => {
                toastService.success("Sucesso", "Contrato editado com sucesso");
                carregarContratos();
                setShowModalContratoEditar(false);
            }).catch(error => {
                console.log(error);
            });
    };

    return (
        <div className="row">
            {cliente.contratos && cliente.contratos.length > 0 ? (
                cliente.contratos.map(contrato => (
                    <div key={contrato.contrato.id} className="col-md-6 col-12">
                        <div className="card shadow mb-3">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-8">
                                        <small className="text-secondary">
                                            <small>
                                                #{contrato.contrato.id}
                                            </small>
                                            {` ${contrato.programa}`}
                                        </small>
                                        <div>
                                            {contrato.descricao}
                                        </div>
                                        <div>
                                            <b>
                                                R${contrato.contrato.valor.toFixed(2)}
                                            </b>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="btn-group float-end">
                                            <button className="btn  btn-sm" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="bi bi-three-dots-vertical"></i>
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li><button onClick={() => editar(contrato)} className="dropdown-item" href="#">Editar</button></li>
                                                <li><h6 className="dropdown-header">Pagamento</h6></li>
                                                <li><button onClick={() => gerarCobranca(contrato, "pix")} className="dropdown-item" href="#">Gerar cobrança PIX</button></li>
                                                <li><button onClick={() => gerarCobranca(contrato, "boleto")} className="dropdown-item" href="#">Gerar cobrança Boleto</button></li>
                                                <li><button onClick={() => liberacaoManual(contrato, "PIX")} className="dropdown-item">Cliente fez PIX</button></li>
                                                <li><h6 className="dropdown-header">Extender</h6></li>
                                                <li><button onClick={() => extenderContrato(contrato)} className="dropdown-item">Extender por 5 dias</button></li>
                                                <li><h6 className="dropdown-header">Status</h6></li>
                                                <li>
                                                    {contrato.ativo ?
                                                        <button onClick={() => alterarAtivo(contrato, false)} className="dropdown-item text-danger" href="#">Desativar</button>
                                                        :
                                                        <button onClick={() => alterarAtivo(contrato, true)} className="dropdown-item text-success" href="#">Ativar</button>
                                                    }
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <hr />

                                <div>
                                    <span className="badge bg-dark">
                                        {moment(contrato.contrato.validade).format("DD/MM/YYYY")}
                                    </span>
                                    &ensp;
                                    {
                                        contrato.vencido ?
                                            <span className="badge bg-danger">
                                                Vencido á {contrato.diasRestantes} dias
                                            </span>
                                            :
                                            <span className="badge bg-secondary">
                                                {contrato.diasRestantes} dias restantes
                                            </span>
                                    }
                                    &ensp;
                                    {
                                        contrato.ativo ?
                                            <span className="badge bg-success">
                                                Contato ativo
                                            </span>
                                            :
                                            <span className="badge bg-danger">
                                                Contrato inativo
                                            </span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>))) : false}
            <Modal show={showModalContratoEditar} onHide={() => setShowModalContratoEditar(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="fs-4">Editar contrato
                        <small className="text-secondary ms-2">
                            #{contratoEdicao && contratoEdicao.contrato && contratoEdicao.contrato.id}
                        </small>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {contratoEdicao && contratoEdicao.contrato &&
                        <form onSubmit={(e) => { salvarContratoEdicao(e) }}>
                            <div className="form-floating mb-3">
                                <select className="form-select" value={contratoEdicao.contrato.idproduto} onChange={(e) => { setContratoEdicao({ ...contratoEdicao, contrato: { ...contratoEdicao.contrato, idproduto: e.target.value } }) }}>
                                    <option value="0">Selecione um programa</option>
                                    {planosEdicao && planosEdicao.map(plano => (
                                        <option key={plano.id} value={plano.id}>{plano.nome}</option>
                                    ))}
                                </select>
                                <label htmlFor="descricao">Plano Atual</label>
                            </div>
                            <div className="form-floating mb-3">
                                <select className="form-select" value={contratoEdicao.contrato.proximoproduto} onChange={(e) => { setContratoEdicao({ ...contratoEdicao, contrato: { ...contratoEdicao.contrato, proximoproduto: e.target.value } }) }}>
                                    <option value="0">Selecione um programa</option>
                                    {planosEdicao && planosEdicao.map(plano => (
                                        <option key={plano.id} value={plano.id}>{plano.nome}</option>
                                    ))}
                                </select>
                                <label htmlFor="descricao">Próximo Plano</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="number" step={0.1} className="form-control" id="vakior" value={contratoEdicao.contrato.valor || ""} onChange={(e) => { setContratoEdicao({ ...contratoEdicao, contrato: { ...contratoEdicao.contrato, valor: e.target.value } }) }} />
                                <label htmlFor="vakior">Valor</label>
                            </div>
                            <div>
                                <button type="submit" className="btn btn-primary col-12">Salvar</button>
                            </div>
                        </form>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )

}

export default ContratosCliente;