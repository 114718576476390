import React from "react";

import Representantes from "../components/representante/Representantes";

const PageRepresentantes = (props) => {

  document.title = "Pollaris ERP - Representantes";

  return (<div className="container" >
    <Representantes />
  </div >);
};

export default PageRepresentantes;