import { useState } from "react";

import { Link, useParams, useNavigate } from "react-router-dom";

import PollarisApi from "../services/PollarisApi";

import ToastService from "../services/ToastService";

const PageRecuperarSenha = () => {

  const { recoveryCodeParam } = useParams();

  document.title = "ERP | Recuperar Senha";

  return (
    <div className="container">
      <div className="mt-5">
        <div className="col-md-6 offset-md-3 shadow bg-pollaris">
          <div className="row">
            <div className="col-md-5">
              <div className="mt-2"></div>
              <div className="p-4 col-md-12 col-8 offset-md-0 offset-2">
                <img src="/assets/logoPollaris_h.svg" alt="Pollaris" className="img-fluid" />
              </div>
            </div>
            <div className="col-md-7">
              <div className="p-5 bg-light shadow">
                {recoveryCodeParam ?
                  <div>
                    <h5 className="mb-3 text-secondary">
                      Alterar Senha
                    </h5>
                    <div className="text-secondary mb-3" style={{ fontSize: "1em" }}>
                      Código enviado para o seu email.
                    </div>
                    <FormAlterarSenha />
                  </div>
                  :
                  <div>
                    <h5 className="mb-3 text-secondary">
                      Recuperar Senha
                    </h5>
                    <FormEnviarEmail />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

const FormAlterarSenha = () => {

  const { recoveryCodeParam } = useParams();

  const [alterandoSenha, setAlterandoSenha] = useState(false);

  const [codigoDigitado, setCodigoDigitado] = useState("");

  const [senhaDigitada, setSenha] = useState("");

  const [senhaConfirmacao, setSenhaConfirmacao] = useState("");

  const [invalidFormClass, setInvalidFormClass] = useState("");

  const apiPollaris = new PollarisApi(true);

  const toastService = new ToastService();

  const navigate = useNavigate();

  const alterar = (e) => {

    e.stopPropagation();
    e.preventDefault();

    if (!codigoDigitado || !senhaDigitada || !senhaConfirmacao) {

      setInvalidFormClass("was-validated");

      return;
    }

    if (senhaDigitada !== senhaConfirmacao) {

      toastService.error("Opa...", "As senhas devem ser iguais");

      return;
    }

    const data = {

      token: recoveryCodeParam,
      codigo: codigoDigitado,
      senha: senhaDigitada,
      confirmacaoSenha: senhaConfirmacao
    };

    setAlterandoSenha(true);

    apiPollaris
      .post("autenticacao/alterar-senha", data)
      .then(response => {

        setAlterandoSenha(false);

        toastService.success("Sucesso!", "Senha alterada com sucesso!");

        navigate("/login");

      }).catch(error => {

        setAlterandoSenha(false);

        toastService.error("Opa...", error.message);

      }
      );
  }


  return (
    <div className="recuperarSenha">
      <form onSubmit={alterar} className={`needs-validation ${invalidFormClass}`} noValidate >
        <div className="form-floating mb-3">
          <input autoFocus onChange={(e) => setCodigoDigitado(e.target.value)} autoComplete="off" type="phone" className="form-control" id="floatingInputCodigo" required placeholder="codigo" />
          <label htmlFor="floatingInputCodigo">Código</label>
        </div>

        <div className="form-floating mb-3">
          <input onChange={(e) => setSenha(e.target.value)} type="password" autoComplete="off" className="form-control" id="floatingInputSenha" required placeholder="senha" />
          <label htmlFor="floatingInputSenha">Nova senha</label>
        </div>

        <div className="form-floating mb-3">
          <input onChange={(e) => setSenhaConfirmacao(e.target.value)} type="password" autoComplete="off" className="form-control" id="floatingInputConfirmacaoSenha" required placeholder="confirmacaosenha" />
          <label htmlFor="floatingInputConfirmacaoSenha">Confirmação senha</label>
        </div>

        <div className="mb-3">
          {alterandoSenha ?
            <button className="btn btn-pollaris col-12" type="button" disabled>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Aguarde...
            </button>
            :
            <button className="btn btn-pollaris col-12"> <i className="bi bi-check"></i> Alterar</button>
          }
        </div>
        <div className="text-center">
          {
            alterandoSenha ?
              <button type="button" disabled className="btn btn-link text-secondary btn-sm" style={{ textDecoration: "none" }}>
                Lembrou sua senha?
              </button>
              :
              <Link type="button" className="btn btn-link text-secondary btn-sm" to="/login" style={{ textDecoration: "none" }}>
                Lembrou sua senha?
              </Link>
          }
        </div>
      </form>
    </div>
  )

}

const FormEnviarEmail = ({ onEmailSended }) => {

  const pollarisApi = new PollarisApi(true);

  const toastService = new ToastService();

  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const [sendingEmail, setSendingEmail] = useState(false);

  const [invalidFormClass, setInvalidFormClass] = useState("");

  const recuperar = (e) => {

    e.stopPropagation();
    e.preventDefault();

    //check email is valid

    if (!email) {

      setInvalidFormClass("was-validated");

      return;
    }

    setInvalidFormClass("");

    setSendingEmail(true);

    pollarisApi
      .post(`usuarios/recuperar-senha/${email}`, null)
      .then(response => {

        setSendingEmail(false);

        var data = response;

        toastService.success("Sucesso", "Enviamos um código para o seu email.");

        navigate("/recuperar/" + data.token);
      }
      )
      .catch(error => {

        setSendingEmail(false);

        toastService.error("Falha", error.message);

      }
      );

  }

  return (
    <div className="recuperarSenha">
      <form onSubmit={recuperar} className={`needs-validation ${invalidFormClass}`} noValidate >
        <div className="form-floating mb-3">
          <input autoFocus onChange={(e) => setEmail(e.target.value)} type="email" className="form-control" id="floatingInput" required placeholder="name@example.com" />
          <label htmlFor="floatingInput">e-mail</label>
        </div>
        <div className="mb-3">
          {sendingEmail ?
            <button className="btn btn-pollaris col-12" type="button" disabled>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Aguarde...
            </button>
            :
            <button className="btn btn-pollaris col-12"> <i className="bi bi-send"></i> Recuperar</button>
          }
        </div>
        <div className="text-center">
          {
            sendingEmail ?
              <button type="button" disabled className="btn btn-link text-secondary btn-sm" style={{ textDecoration: "none" }}>
                Lembrou sua senha?
              </button>
              :
              <Link type="button" className="btn btn-link text-secondary btn-sm" to="/login" style={{ textDecoration: "none" }}>
                Lembrou sua senha?
              </Link>
          }
        </div>
      </form>
    </div>
  )
}

export default PageRecuperarSenha;