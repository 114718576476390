import React from "react";
import Produtos from "../components/produto/Produtos";

const PageProdutos = (props) => {

  document.title = "Pollaris ERP - Produtos";

  return (<div className="container" >
    <Produtos />
  </div >);
};

export default PageProdutos;