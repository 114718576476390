
import { Link } from 'react-router-dom';

import React, { useEffect, useState } from "react";

import PollarisApi from "../services/PollarisApi";

import Cobrancas from "../components/cobranca/Cobrancas";

import Clientes from '../components/cliente/Clientes';

const PageHome = (props) => {

  document.title = "Pollaris ERP - Home";

  let loaded = false;

  const pollarisApi = new PollarisApi();

  const [overview, setOverview] = useState(null);

  useEffect(() => {

    if (loaded) return;

    loaded = true;

    const loadOverview = () => {
      pollarisApi.get("financeiro/overview")
        .then(response => {
          setOverview(response);
        })
        .catch(error => {
          console.log(error);
        });
    }

    loadOverview();

  }, []);

  return (
    overview ? (
      <div className="container" >
        <div className="row">
          <CardOverview>
            <span className="text-muted" ><i className="bi bi-people-fill"></i> Clientes</span>
            <div className="mt-3">
              <h4>{overview.clientes.ativos}</h4>
              {overview.clientes.vencidos > 0 ?
                <small className="text-danger" title="Contratos vencidos">
                  <i className="bi bi-calendar-x"></i>
                  {` ${overview.clientes.vencidos}`}
                </small> : false
              }
              {" "}
              {overview.clientes.vencendo > 0 ?
                <small className="text-warning" title="Vencendo nos próximos 5 dias">
                  <i className="bi bi-calendar2-minus"></i>
                  {` ${overview.clientes.vencendo}`}
                </small> : false
              }
              <small className="text-success" title="Sem contratos vencidos">
                <i className="bi bi-calendar-check"></i>
                {` ${overview.clientes.ativos - overview.clientes.vencidos}`}
              </small>
            </div>
          </CardOverview>
          <CardOverview>
            <span className="text-muted"><i className="bi bi-newspaper"></i> Contratos</span>
            <div className="mt-3">
              <h4 >R${overview.expectativa.total.toFixed(2)}</h4>
              <small title="Contratos vencidos">
                {overview.expectativa.vencidos > 0 ?
                  <span className="text-danger">
                    <i className="bi bi-calendar-x"></i>
                    {` R$${overview.expectativa.vencidos.toFixed(2)}`}
                  </span> :
                  <span className="text-secondary">
                    <i className="bi bi-arrow-down"></i>
                    R$0.00
                  </span>
                }

              </small>
            </div>
          </CardOverview>
          <CardOverview>
            <span className="text-muted"><i className="bi bi-currency-dollar"></i> Despesa</span>
            <div className="mt-3">
              <h4>R${overview.despesa.total.toFixed(2)}</h4>
              <small><Link className='text-decoration-none' to="/despesas">Ver Mais</Link></small>
            </div>
          </CardOverview>
          <CardOverview>
            <span className="text-muted"><i className="bi bi-piggy-bank"></i> Receita</span>
            <div className="mt-3">
              <h4 >R${overview.receita.total.toFixed(2)}</h4>
              <small title="Sobre as despesas">
                {overview.receita.total > overview.despesa.total ?
                  <span className="text-success">
                    <i className="bi bi-arrow-up"></i>
                    R${overview.lucro.total.toFixed(2)}
                  </span> :
                  <span className="text-danger">
                    <i className="bi bi-arrow-down"></i>
                    R${overview.lucro.total.toFixed(2)}
                  </span>
                }

              </small>
            </div>
          </CardOverview>

        </div>
        <div className='mb-3'>
          <Clientes titulo={"Próximos vencimentos"} categoria={"quase-atrasados-pagantes"} />
        </div>
        <div className='mb-3'>
          <Clientes titulo={"Vencidos"} categoria={"atrasados-pagantes"} />
        </div>
        <div>
          <Cobrancas />
        </div>
      </div >) : <div className='p4 text-center text-secondary'>Loading...</div>
  );
};

const CardOverview = (props) => {
  return (
    <div className="col-md-3 col-6 mb-3">
      <div className="card shadow">
        <div className="card-body">
          {props.children}
        </div>
      </div>
    </div>)
}

export default PageHome;