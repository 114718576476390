import React from "react";
import Cobrancas from "../components/cobranca/Cobrancas";

const PageCobrancas = (props) => {

  document.title = "Pollaris ERP - Cobranças";

  return (<div className="container" >
    <Cobrancas />
  </div >);
};

export default PageCobrancas;