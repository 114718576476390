import { useEffect, useState } from "react";

import moment from "moment";

import PollarisApi from "../../services/PollarisApi";

import ToastService from "../../services/ToastService";

import { useForm } from 'react-hook-form';

import SelectColaborador from "../inputs/SelectColaborador";

const FormDespesa = ({ despesa, onSave }) => {

    const apiPollaris = new PollarisApi();

    const toastService = new ToastService();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {

        toastService.info("Aguarde..", "Salvando...");

        var despesaAtual = { ...despesa };

        const novaDespesa = { ...despesaAtual, ...data };

        novaDespesa.periodicidade = parseInt(novaDespesa.periodicidade);

        const onSuccess = (response) => {
            toastService.success("Sucesso..", "Despesa salva com sucesso");
            onSave();
        };

        const onFail = (error) => {

            console.error(error);

            const message = error?.message || error.title;

            toastService.error("Erro ao salvar dados", message);

        }

        if (despesa?.id) {

            apiPollaris
                .put(`despesas/${despesa.id}`, novaDespesa)
                .then(onSuccess)
                .catch(onFail);

        } else {

            apiPollaris
                .post(`despesas/`, novaDespesa)
                .then(onSuccess)
                .catch(onFail);
        }


    }

    return (<form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-floating mb-3">
            <input type="text" className="form-control " {...register('descricao', { value: despesa?.descricao, required: true })} />
            <label>Descricao</label>
        </div>
        <div className="form-floating mb-3">
            <textarea type="text" className="form-control " {...register('informacoes', { value: despesa?.informacoes, required: true })} >
            </textarea>
            <label>Informações</label>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="form-floating mb-3">
                    <select type="datetime-local" className="form-control " {...register('periodicidade', { value: despesa?.periodicidade, required: true })} >
                        <option value="0">Nenhum</option>
                        <option value="1">Mensal</option>
                        <option value="2">Anual</option>
                    </select>
                    <label>Periodicidade</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-floating mb-3">
                    <input type="date" className="form-control" {...register('vencimento', { value: moment(despesa?.vencimento).format("YYYY-MM-DD"), required: true })} />
                    <label>Vencimento</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-floating mb-3">
                    <input type="number" step="0.01" className="form-control " {...register('valor', { value: despesa?.valor, required: true })} />
                    <label>Valor</label>
                </div>
            </div>
            <div className="col-md-6">
                <button className="btn form-control col-12 btn-success-pollaris">Salvar</button>
            </div>
        </div>
    </form>
    );
};

export default FormDespesa;