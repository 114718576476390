import { useEffect, useState } from "react";

import PollarisApi from "../../services/PollarisApi";

import ToastService from "../../services/ToastService";

import { useForm } from 'react-hook-form';

import SelectColaborador from "../inputs/SelectColaborador";
import moment from "moment";

const FormProduto = ({ onSave, produtoSelecionado }) => {

    let loaded = false;

    const [produto, setProduto] = useState(produtoSelecionado);

    const apiPollaris = new PollarisApi();

    const toastService = new ToastService();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {

        toastService.info("Aguarde..", "Salvando...");

        var produtoAtual = { ...produto };

        const newProduto = { ...produtoAtual, ...data };

        newProduto.ativo = true;

        const onError = (error) => {

            console.error(error);

            const message = error?.message || error.title;

            toastService.error("Erro ao salvar dados", message);

        };

        const onSuccess = (response) => {
            toastService.success("Sucesso..", "Dados salvos com sucesso...");

            onSave();
        };

        apiPollaris
            .post(`produtos`, newProduto)
            .then(onSuccess)
            .catch(onError);

    }

    return (<form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
            <div className="col-md-6">
                <div className="form-floating mb-3">
                    <select type="text" className="form-control " {...register('programa', { value: produto?.programa, required: false })} >
                        <option value="snack">Snack</option>
                        <option value="shop">Shop</option>
                        <option value="green">Green</option>
                        <option value="oos">Oos</option>
                        <option value="tech">Tech</option>
                        <option value="resume">Resume</option>
                        <option value="stock">Stock</option>
                    </select>
                    <label>Programa</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-floating mb-3">
                    <input type="text" className="form-control " {...register('nome', { value: produto?.nome, required: false })} />
                    <label>Nome</label>
                </div>
            </div>
            <div className="col-md-4">
                <div className="form-floating mb-3">
                    <input type="text" className="form-control " {...register('link', { value: produto?.link, required: false })} />
                    <label>Link</label>
                </div>
            </div>
            <div className="col-md-4">
                <div className="form-floating mb-3">
                    <input type="number" step={0.01} className="form-control " {...register('implantacao', { value: produto?.implantacao, required: false })} />
                    <label>Implantação</label>
                </div>
            </div>
            <div className="col-md-4">
                <div className="form-floating mb-3">
                    <input type="number" step={0.01} className="form-control " {...register('mensalidade', { value: produto?.mensalidade, required: false })} />
                    <label>Mensalidade</label>
                </div>
            </div>
            <div className="col-md-12">
                <div className="form-floating mb-3">
                    <input type="text" className="form-control " {...register('icone', { value: produto?.icone, required: false })} />
                    <label>Icone</label>
                </div>
            </div>
            <div className="col-md-12">
                <div className="form-floating mb-3">
                    <textarea type="text" rows="10" className="form-control " {...register('descricao', { value: produto?.descricao, required: false })} />
                    <label>Descrição</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className="mb-3">
                    <button type="submit" className="btn form-control col-12 btn-success-pollaris btn-lg" >
                        <i className="bi bi-check"></i> Salvar
                    </button>
                </div>
            </div>
        </div>
    </form>
    );
};

export default FormProduto;
