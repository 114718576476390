import { useEffect, useState } from "react"

import PollarisApi from "../../services/PollarisApi";

const SelectColaborador = ({ register, startValue }) => {

    let loaded = false;

    const [colaborador, setColaborador] = useState(startValue);

    const [colaboradores, setColaboradores] = useState([]);

    const apiPollaris = new PollarisApi();

    useEffect(() => {

        if (loaded) return;

        loaded = true;

        apiPollaris.get("colaboradores")
            .then(response => {
                setColaboradores(response);
            }).catch(error => {

                console.log(error);
            });

    }, []);

    const handleChange = (event) => {

        setColaborador(event.target.value);
    }

    return (
        <div className="form-floating mb-3">
            <select value={colaborador} title="Selecionar Colaborador" type="text" className="form-control " {...register('colaborador', { onChange: (e) => handleChange(e), required: true })} >
                <option value="0">Nenhum</option>
                {colaboradores.map(colaborador => (
                    <option key={colaborador.id} value={colaborador.id}>{colaborador.id} - {colaborador.nome}</option>
                ))}
            </select>
            <label>Colaborador</label>
        </div >
    )
}

export default SelectColaborador;