import React from "react";

import Clientes from '../components/cliente/Clientes';

const PageClientes = (props) => {

  document.title = "Pollaris ERP - Clientes";

  return (<div className="container" >
    <Clientes />
  </div >);
};

export default PageClientes;