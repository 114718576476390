
import moment from "moment";

import { Link } from "react-router-dom";

import { useEffect, useState } from "react";

import PollarisApi from "../../services/PollarisApi";

const Clientes = ({ titulo, categoria }) => {

    let loaded = false;

    const [loading, setLoading] = useState(false);

    const [dataDe, setDataDe] = useState(moment().format("YYYY-MM-01"));

    const [pesquisa, setPesquisa] = useState("");

    const [dataAte, setDataAte] = useState(moment().format("YYYY-MM-DD"));

    const [clientes, setClientes] = useState([]);

    const [sortedField, setSortedField] = useState(null);

    const pollarisApi = new PollarisApi();

    const loadClientes = (e) => {

        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        setLoading(true);

        pollarisApi.get("clientes?categoria=" + categoria)
            .then(response => {
                setLoading(false);
                setClientes(response);
            }).catch(error => {
                setLoading(false);
                console.log(error);
            }
            );

    }

    useEffect(() => {

        if (loaded) return;

        loaded = true;

        loadClientes();

    }, []);

    const matchPesquisaCliente = (cliente) => {

        const pesquisaLower = pesquisa.toLowerCase();

        if (cliente.nome.toLowerCase().includes(pesquisaLower)) return true;

        if (cliente.email.toLowerCase().includes(pesquisaLower)) return true;

        if (cliente.telefone.toLowerCase().includes(pesquisaLower)) return true;

        if (cliente.cnpj.toLowerCase().includes(pesquisaLower)) return true;

        return false;
    }

    const ordernarLista = (field, converter) => {
        const lista = [...clientes];

        if (sortedField === field) {
            lista.reverse();
            setClientes(lista);
            return;
        }

        setSortedField(field);

        lista.sort((a, b) => {

            let valueA = a[field];

            let valueB = b[field];

            if (converter) {

                valueA = converter(valueA);

                valueB = converter(valueB);

            }

            if (valueA < valueB) return -1;

            if (valueA > valueB) return 1;

            return 0;

        });

        setClientes(lista);
    }

    return (
        <div className="cobrancas">
            <div className="card shadow">
                <div className="card-body">

                    <h5>{titulo ? titulo : "Clientes"}
                        <span className="dropstart float-end d-none">
                            <span data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                <i className="bi bi-funnel"></i>
                            </span>
                            <form onSubmit={loadClientes} className="dropdown-menu p-4">
                                <div className="mb-3">
                                    <label htmlFor="inputDataInicial" className="form-label">Data Inicial</label>
                                    <input type="date" className="form-control" id="inputDataInicial" value={dataDe} onChange={(e) => setDataDe(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="inputDataFinal" className="form-label">Data Final</label>
                                    <input type="date" className="form-control" id="inputDataFinal" value={dataAte} onChange={(e) => setDataAte(e.target.value)} />
                                </div>
                                {loading ?
                                    <button className="btn btn-success-pollaris col-12" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span className="sr-only"> Carregando...</span>
                                    </button> :
                                    <button type="submit" className="btn btn-success-pollaris col-12"><i className="bi bi-funnel"></i> Filtrar</button>
                                }
                            </form>
                        </span>
                    </h5>
                    <hr />

                    <div className="mb-3">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Pesquisar" value={pesquisa} onChange={(e) => setPesquisa(e.target.value)} />
                            <button className="btn btn-outline-secondary" type="button" id="button-addon2"><i className="bi bi-search"></i></button>
                        </div>
                    </div>

                    {clientes.length > 0 ?
                        <div className="table-responsive">
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th className="cursor-pointer" onClick={() => ordernarLista("id", (d) => parseInt(d))} >Código</th>
                                        <th className="cursor-pointer" onClick={() => ordernarLista("dataHora", (d) => moment(d).format("YYYYMMDD"))} >Data</th>
                                        <th className="cursor-pointer" onClick={() => ordernarLista("cnpj", (d) => parseInt(d.replace(/\D/g, "")))} >CPF/CNPJ</th>
                                        <th className="cursor-pointer" onClick={() => ordernarLista("nome")} >Nome</th>
                                        <th className="cursor-pointer" onClick={() => ordernarLista("email")} >Email</th>
                                        <th className="cursor-pointer" onClick={() => ordernarLista("telefone")} >Telefone</th>
                                        <th className="cursor-pointer" onClick={() => ordernarLista("cnpjMatriz")} >Matriz</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {clientes.filter(cliente => matchPesquisaCliente(cliente)).map(cliente => (
                                        <tr key={cliente.id}>
                                            <td>{cliente.id}</td>
                                            <td>{moment(cliente.dataHora).format("DD/MM/YYYY")}</td>
                                            <td>{cliente.cnpj}</td>
                                            <td>{cliente.nome}</td>
                                            <td>{cliente.email}</td>
                                            <td>{cliente.telefone}</td>
                                            <td>{cliente.cnpjMatriz}</td>
                                            <td>
                                                <Link className="btn btn-outline-primary btn-sm" to={`/clientes/${cliente.id}`}>
                                                    <i className="bi bi-pencil"></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div> :
                        <div className="alert alert-secondary">
                            <i className="bi bi-info-circle"></i> Nenhum cliente encontrado.
                        </div>
                    }

                </div>
            </div>
        </div>
    );

}

export default Clientes;
