
import moment from "moment";

import { Link } from "react-router-dom";

import { useEffect, useState } from "react";

import PollarisApi from "../../services/PollarisApi";
import { Modal, ModalBody } from "react-bootstrap";
import FormRepresentante from "./FormRepresentante";
import ToastService from "../../services/ToastService";

const Representantes = ({ titulo, categoria }) => {

    let loaded = false;

    const toastService = new ToastService();

    const [loading, setLoading] = useState(false);

    const [dataDe, setDataDe] = useState(moment().format("YYYY-MM-01"));

    const [dataAte, setDataAte] = useState(moment().format("YYYY-MM-DD"));

    const [showModal, setShowModal] = useState(false);

    const [representante, setRepresentante] = useState(null);

    const [representantes, setrepresentantes] = useState([]);

    const pollarisApi = new PollarisApi();

    const loadRepresentantes = (e) => {

        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        setLoading(true);

        pollarisApi.get("representantes")
            .then(response => {
                setLoading(false);
                setrepresentantes(response);
            }).catch(error => {
                setLoading(false);
                console.log(error);
            }
            );

    }

    const removerRepresentante = (id) => {

        pollarisApi.delete(`representantes/${id}`)


            .then(response => {

                loadRepresentantes();

                toastService.success("Sucesso", "Representante removido ");

            }).catch(error => {

                console.log(error);
            }

            );

    }

    useEffect(() => {

        if (loaded) return;

        loaded = true;

        loadRepresentantes();

    }, []);

    const selectRepresentante = (representante) => {

        setRepresentante(representante);

        setShowModal(true);

    }



    return (
        <div className="representantes">
            <div className="card shadow">
                <div className="card-body">
                    <div>
                        <h5>
                            {titulo ? titulo : "Representantes"}
                            <button onClick={() => selectRepresentante({})} className="btn btn-outline-dark float-end">
                                + Novo
                            </button>
                        </h5>
                        <span className="dropstart float-end d-none">
                            <span data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                <i className="bi bi-funnel"></i>
                            </span>
                            <form onSubmit={loadRepresentantes} className="dropdown-menu p-4">
                                <div className="mb-3">
                                    <label htmlFor="inputDataInicial" className="form-label">Data Inicial</label>
                                    <input type="date" className="form-control" id="inputDataInicial" value={dataDe} onChange={(e) => setDataDe(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="inputDataFinal" className="form-label">Data Final</label>
                                    <input type="date" className="form-control" id="inputDataFinal" value={dataAte} onChange={(e) => setDataAte(e.target.value)} />
                                </div>
                                {loading ?
                                    <button className="btn btn-success-pollaris col-12" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span className="sr-only"> Carregando...</span>
                                    </button> :
                                    <button type="submit" className="btn btn-success-pollaris col-12"><i className="bi bi-funnel"></i> Filtrar</button>
                                }
                            </form>
                        </span>

                    </div>

                    <hr />
                    {representantes.length > 0 ?
                        <div className="table-responsive">
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Código</th>
                                        <th>Nome</th>
                                        <th>Email</th>
                                        <th>Telefone</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {representantes.map(rep => (
                                        <tr key={rep.id}>
                                            <td>{rep.id}</td>
                                            <td>{rep.nome}</td>
                                            <td>{rep.email}</td>
                                            <td>{rep.telefone}</td>
                                            <td>
                                                <button onClick={() => selectRepresentante(rep)} className="btn btn-outline-primary btn-sm">
                                                    <i className="bi bi-pencil"></i>
                                                </button>
                                            </td>
                                            <td>
                                                <button onClick={() => removerRepresentante(rep.id)} className="btn btn-outline-danger btn-sm">
                                                    <i className="bi bi-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div> :
                        <div className="alert alert-secondary">
                            <i className="bi bi-info-circle"></i> Nenhum representante encontrado.
                        </div>
                    }

                </div>
            </div>

            <Modal show={showModal} onExit={() => loadRepresentantes()} dialogClassName="modal-lg">
                <ModalBody>
                    <div className="mb-3">
                        <h5>
                            {representante ? "Editar" : "Novo"} representante
                            <button className="btn btn-close float-end" onClick={() => setShowModal(false)}></button>
                        </h5>

                    </div>
                    <FormRepresentante onSave={() => setShowModal(false)} selectedRepresentante={representante} />
                </ModalBody>
            </Modal>

        </div>
    );

}

export default Representantes;