
import { Link } from 'react-router-dom';

import moment from 'moment';

import PollarisApi from '../services/PollarisApi';

import ToastService from '../services/ToastService';

import React, { useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal";

import ModalBody from "react-bootstrap/ModalBody";

const PageProspectados = (props) => {

  let loaded = false;

  document.title = "Pollaris ERP - Prospectados";

  const pollarisAPi = new PollarisApi();

  const toasService = new ToastService();

  const statusClass = ["secondary", "warning", "success", "danger"];

  const status = ["Criado", "Em Contato", "Efetivado", "Cancelado"];

  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [prospectados, setProspectados] = useState([]);

  const [prospectado, setProspectado] = useState(null);

  const [motivo, setMotivo] = useState("");

  const [statusProspectado, setStatusProspectado] = useState("");

  const loadProspectados = async () => {

    pollarisAPi.get("prospectados")
      .then(response => {
        setProspectados(response);
      }).catch(error => {
        console.log(error);
      })
  }


  useEffect(() => {

    if (loaded) return;

    loaded = true;

    loadProspectados();

  }, []);

  const gerarNovoProspectado = () => {

    setLoading(true);

    pollarisAPi
      .get("leads/BaseDeCnpjs")
      .then(response => {
        setLoading(false);
        setProspectado(response.prospectado);
        setStatusProspectado(response.prospectado.status);
        setShowModal(true);
        toasService.success("Prospectado gerado!", response.prospectado.nome);
      }).catch(error => {
        setLoading(false);
        toasService.error("Falha ao gerar prospectado", error.message)
      })
  }


  const selectProspectado = (prospectado) => {

    pollarisAPi
      .get("prospectados/" + prospectado.id)
      .then(response => {
        setLoading(false);
        setProspectado(response);
        setStatusProspectado(response.status);
        setShowModal(true);
      }).catch(error => {
        setLoading(false);
        toasService.error("Falha ao gerar prospectado", error.message)
      })
  }

  const saveProspectado = () => {

    console.log("aaa");

    if (statusProspectado === "3") {
      if (!motivo) {
        toasService.error("Opa...", "Você precisa informar o motivo para este status.");
        return;
      }
    }

    prospectado.status = parseInt(statusProspectado);
    prospectado.Motivo = motivo;

    pollarisAPi.put(`prospectados/${prospectado.id}`, prospectado)
      .then(response => {

        toasService.success("Atualizado!", response.nome);

        if (statusProspectado === "3") {
          setMotivo("");
          setStatusProspectado("0");
          setShowModal(false);
        } else {
          selectProspectado(prospectado);
        }

      }).catch(error => {
        console.error(error);
        toasService.error("Falha ao atualizar status", error.message)
      });
  }

  return (
    <div className='container'>
      <div className="card shadow">
        <div className="card-body">
          <div>
            <h5>
              Prospectados
              {
                loading ?
                  <button className="float-end btn btn-sm btn-pollaris" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    {" "}Gerando ...
                  </button>
                  :
                  <button onClick={() => gerarNovoProspectado()} className="btn float-end btn-sm btn-pollaris" title="Editar prospectado">
                    Gerar
                  </button>
              }
            </h5>
          </div>
          <hr />

          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Produto</th>
                  <th>CNPJ</th>
                  <th>Nome</th>
                  <th>Cidade</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "0.9em" }}>
                {prospectados?.map(prospectado => (
                  <tr key={prospectado.id} onClick={() => selectProspectado(prospectado)}>
                    <td>{moment(prospectado.dataHora).format("DD/MM/yyyy HH:mm")}</td>
                    <td>{prospectado.produto}</td>
                    <td>{prospectado.cnpj}</td>
                    <td>{prospectado.razao_social}</td>
                    <td>{prospectado.estado} - {prospectado.cidade}</td>
                    <td>
                      <span className={`badge bg-${statusClass[prospectado.status]}`}>{status[prospectado.status]}</span>
                    </td>
                  </tr>
                ))}
              </tbody>

            </table>
          </div>
        </div>
      </div>

      <Modal show={showModal} onExit={() => loadProspectados()}>
        <ModalBody>
          <div className="mb-3">
            <h5>
              <button className="btn btn-close float-end" onClick={() => setShowModal(false)}></button>
            </h5>
            {
              prospectado ?
                <div >
                  <h5>Prospectado</h5>
                  <hr />
                  <div className='shadow border p-4'>
                    <div className='mb-3'>
                      <div className='mb-3'>
                        <small className='text-secondary'>
                          {prospectado.cnpj}
                          <div>
                            {prospectado.razao_social}
                          </div>
                          <div>
                            <small className='text-secondary'>
                              {prospectado.tipocnpj}
                            </small>
                          </div>
                        </small>
                      </div>
                      <h5>
                        {prospectado.nome}
                      </h5>
                      <div>
                        <i className="bi bi-person-badge"></i> {prospectado.responsavel}
                      </div>
                    </div>
                    <div className='mb-2'>
                      {prospectado.email ?
                        <a target="blank" className='col-12 btn btn-outline-danger' href={`mailto:${prospectado.email}`}>
                          <i className='bi bi-envelope'></i>
                          {" "}{prospectado.email}
                        </a>
                        :
                        <button disabled className='col-12 btn btn-outline-secondary'>
                          <i className='bi bi-envelope'></i>
                          {" "} sem email
                        </button>
                      }
                    </div>
                    <div className='mb-2'>
                      <div className='row'>
                        {

                          prospectado.telefones.map((telefone, index) => (
                            <div className='col-md-6 mb-2' key={index}>
                              <a target="blank" className='btn btn-outline-primary col-12' href={`tel:${telefone}`}>
                                <i className='bi bi-telephone'></i>
                                {" "}{telefone.replace(/^(\d{2})(\d{4,5})(\d{4})$/, "($1) $2-$3")}
                              </a>

                            </div>
                          ))
                        }
                      </div>
                    </div>
                    <div className='mb-2'>
                      <div className='row'>
                        {

                          prospectado.telefones.map((telefone, index) => (
                            <div className='col-md-6 mb-2' key={index}>
                              <a target="blank" className='btn btn-outline-success col-12' href={`https://api.whatsapp.com/send?phone=55${telefone}`}>
                                <i className='bi bi-whatsapp'></i>
                                {" "} {telefone.replace(/^(\d{2})(\d{4,5})(\d{4})$/, "($1) $2-$3")}
                              </a>

                            </div>
                          ))
                        }
                      </div>
                    </div>
                    <div className='mb-3'>
                      <a target="blank" href={`https://www.google.com.br/maps/search/${prospectado.endereco}, ${prospectado.numero} - ${prospectado.bairro} ,
                          ${prospectado.cidade} - ${prospectado.estado} - ${prospectado.cep}`} className='btn btn-outline-dark col-12'>
                        <i className="bi bi-geo-alt"></i>
                        {" "}
                        <small>
                          {prospectado.endereco}, {prospectado.numero} - {prospectado.bairro} ,
                          {prospectado.cidade} - {prospectado.estado} - {prospectado.cep}
                        </small>
                      </a>
                    </div>
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne1">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="false" aria-controls="collapseOne1">
                            Atualizar
                          </button>
                        </h2>
                        <div id="collapseOne1" className="accordion-collapse collapse" aria-labelledby="headingOne1" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <div className='mb-3'>
                              <div className="form-floating">
                                <select onChange={(e) => setStatusProspectado(e.target.value)} value={statusProspectado} className="form-select" id="floatingSelect" aria-label="Floating label select example">
                                  <option value="0">Criado</option>
                                  <option value="1">Em Contato</option>
                                  <option value="2">Efetivado</option>
                                  <option value="3">Recusado</option>
                                </select>
                                <label htmlFor="floatingSelect">Status</label>
                              </div>
                            </div>
                            <div className='mb-3'>
                              <div className="form-floating">
                                <textarea onChange={(e) => setMotivo(e.target.value)} value={motivo} className="form-control" id="floatingText" aria-label="Floating label select example">

                                </textarea>
                                <label htmlFor="floatingSelect">Descrição</label>
                              </div>
                            </div>
                            <div className='mb-3'>
                              <button className='btn btn-success-pollaris col-12' onClick={() => saveProspectado()}>
                                Atualizar
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            Histórico
                          </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <table style={{ fontSize: "0.9em" }} className="table table-striped table-hover">
                              <thead>
                                <tr>
                                  <th>Data</th>
                                  <th>Status</th>
                                  <th>Historico</th>
                                </tr>
                              </thead>
                              <tbody>
                                {prospectado.historico.map((historico, index) => (
                                  <tr key={index}>
                                    <td>{moment(historico.dataHora).format("DD/MM/yyyy HH:mm")}</td>
                                    <td>{status[historico.status]}</td>
                                    <td>{historico.descricao}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className='mb-3'>
                    <div className='row'>
                      <div className='col-6'>
                        <button className='btn btn-info-pollaris' onClick={() => setShowModal(false)}>
                          <i className="bi bi-arrow-left"></i>
                          {' '}Voltar
                        </button>
                      </div>
                    </div>
                  </div>


                </div> : null
            }
          </div>
        </ModalBody>
      </Modal>

    </div>
  );
};
export default PageProspectados;