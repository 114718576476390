
import moment from "moment";

import { Link } from "react-router-dom";

import { useEffect, useState } from "react";

import PollarisApi from "../../services/PollarisApi";

const Cobrancas = ({ idCliente }) => {

    let loaded = false;

    const [loading, setLoading] = useState(false);

    const [dataDe, setDataDe] = useState(moment().format("YYYY-MM-01"));

    const [dataAte, setDataAte] = useState(moment().format("YYYY-MM-DD"));

    const [cobrancas, setCobrancas] = useState([]);

    const pollarisApi = new PollarisApi();

    const loadCobrancas = (e) => {

        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        setLoading(true);

        let request = `cobrancas?dataDe=${dataDe}&dataAte=${dataAte}`;

        if (idCliente) {
            request += `&idCliente=${idCliente}`;
        }

        pollarisApi.get(request)
            .then(response => {
                setLoading(false);
                setCobrancas(response);
            }).catch(error => {
                setLoading(false);
                console.log(error);
            }
            );

    }

    useEffect(() => {

        if (loaded) return;

        loaded = true;

        loadCobrancas();

    }, []);

    return (
        <div className="cobrancas">
            <div className="card shadow">
                <div className="card-body">
                    <h5>Cobranças
                        <span className="dropstart float-end">
                            <span data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                <i className="bi bi-funnel"></i>
                            </span>
                            <form onSubmit={loadCobrancas} className="dropdown-menu p-4">
                                <div className="mb-3">
                                    <label htmlFor="inputDataInicial" className="form-label">Data Inicial</label>
                                    <input type="date" className="form-control" id="inputDataInicial" value={dataDe} onChange={(e) => setDataDe(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="inputDataFinal" className="form-label">Data Final</label>
                                    <input type="date" className="form-control" id="inputDataFinal" value={dataAte} onChange={(e) => setDataAte(e.target.value)} />
                                </div>
                                {loading ?
                                    <button className="btn btn-success-pollaris col-12" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span className="sr-only"> Carregando...</span>
                                    </button> :
                                    <button type="submit" className="btn btn-success-pollaris col-12"><i className="bi bi-funnel"></i> Filtrar</button>
                                }
                            </form>
                        </span>
                    </h5>
                    <hr />
                    {cobrancas.length > 0 ?
                        <div className="table-responsive">
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Data</th>
                                        <th>Programa</th>
                                        {idCliente ? null : <th>Cliente</th>}
                                        <th>Forma</th>
                                        <th>Status</th>
                                        <th>Valor</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cobrancas.map(cobranca => (
                                        <tr key={cobranca.id}>
                                            <td>
                                                <small>{moment(cobranca.dataHora).format("DD/MM/YYYY HH:MM:ss")}</small>
                                            </td>
                                            <td>
                                                <small>
                                                    {cobranca.programa}
                                                </small>
                                            </td>
                                            {
                                                idCliente ? null :
                                                    <td>
                                                        <small>
                                                            <Link className="text-decoration-none" to={`/clientes/${cobranca.idCliente}`}>
                                                                {cobranca.idCliente} - {cobranca.nomeCliente}
                                                            </Link>
                                                        </small>
                                                    </td>
                                            }
                                            <td>
                                                <span className="text-secondary" style={{ fontSize: "0.6em" }}>
                                                    {cobranca.tipo}
                                                </span>
                                                {" "} {cobranca.pagamento}
                                            </td>
                                            <td>
                                                {
                                                    cobranca.dataestorno ?
                                                        <span className="badge bg-warning text-dark">
                                                            <i className="bi bi-x-circle"></i> Estornado {moment(cobranca.dataestorno).format("DD/MM/YYYY")}
                                                        </span> :
                                                        cobranca.datadeletado ?
                                                            <span className="badge bg-danger">
                                                                <i className="bi bi-trash"></i> Cancelado {moment(cobranca.datadeletado).format("DD/MM/YYYY")}
                                                            </span> :
                                                            cobranca.datapago ?
                                                                <span className="badge bg-success">
                                                                    <i className="bi bi-check-circle"></i> Pago {moment(cobranca.datapago).format("DD/MM/YYYY")}
                                                                </span> :
                                                                <span className="badge bg-secondary">
                                                                    <i className="bi bi-exclamation-triangle"></i> Pendente
                                                                </span>
                                                }
                                            </td>
                                            <td>
                                                <small>
                                                    R${cobranca.valor.toFixed(2)}
                                                    <small className="text-danger">
                                                        {" "}  - R${cobranca.taxa.toFixed(2)}
                                                    </small>
                                                </small>
                                            </td>
                                            <td>R${cobranca.valorLiquido.toFixed(2)}</td>
                                            <td>
                                                <Link to={`/cobranca/${cobranca.id}`} className="btn btn-link">
                                                    <i className="bi bi-box-arrow-up-right"></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div> :
                        <div className="alert alert-secondary">
                            <i className="bi bi-info-circle"></i> Nenhuma cobrança encontrada.
                        </div>
                    }

                </div>
            </div>
        </div>
    );

}

export default Cobrancas;